<template>
  <div class="mt-5 md:mt-8">
    <div class="container no-print">
      <LayoutColumns>
        <template #left>
          <ListNames
            class="order-3 lg:order-none"
            :count="5"
            placement="side"
          />
          <ListJobs
            v-if="true"
            class="order-5 lg:order-none"
            :count="4"
            :random="true"
          />
          <AdsLivewrappedAd class="hidden lg:block" id="rec1" />
          <AdsLivewrappedAd
            v-if="true"
            class="lg:hidden order-2"
            id="mob2"
          />
          <ListQA
            v-if="false"
            class="order-last lg:order-none"
            :count="5"
            placement="side"
            label="MinisterQA"
            label-link="Routes.QA"
          />
          <CommonUserWidget
            v-if="
              userStore.user &&
              userStore.hasLoginType('UsernamePassword', 'Sso')
            "
            :user="userStore.user"
            class="order-9 lg:order-none"
            placement="side"
          />
        </template>
        <template #main>
          <ListArticles
            class="order-2 lg:order-none"
            :frontend="true"
            :count="4"
            :offset="0"
            :paper="1"
            :lazy="false"
            :preload-first-image="true"
            placement="main"
          />
          <ArticleSponsoredInlineTeaser
            v-if="false"
            class="order-3 lg:order-none"
          />
          <ArticleSponsoredTeaser v-else class="order-3 lg:order-none" />
          <ListBubbles class="order-1 lg:order-none" />
          <ListArticles
            class="order-4 lg:order-none"
            :offset="4"
            :frontend="true"
            :paper="1"
            :no-fillup="true"
            placement="main"
          />
          <div id="nativendo-start1" v-if="true" />
          <NewsletterSignup class="hidden lg:block" />
        </template>
        <template #right>
          <ListJobs
            v-if="false"
            class="order-5 lg:order-none"
            :count="5"
            :random="true"
          />
          <template v-if="false">
            <AdsLivewrappedAd class="hidden lg:block" id="rec2" />
            <AdsLivewrappedAd class="lg:hidden order-6" id="mob3" />
          </template>
          <ListArticles
            v-else-if="true"
            class="order-5 lg:order-none"
            :count="5"
            :offset="0"
            :article-type="90"
            type="articletype"
            label="Chronicles"
            :same-size="true"
            placement="side"
          />
          <ListMostRead class="order-7 lg:order-none" />
          <AdsLivewrappedAd
            v-if="true"
            class="lg:hidden order-3"
            id="mob3"
          />
          <template v-if="false">
            <AdsLivewrappedAd class="hidden lg:block" id="rec3" />
            <AdsLivewrappedAd class="lg:hidden order-10" id="mob4" />
          </template>
          <AdsLivewrappedAd v-else class="hidden lg:block" id="rec2" />
          <ArticleSponsoredTeaser
            v-if="true"
            class="order-10 lg:order-none"
            :compact="true"
            :top="2"
            :offset="1"
          />
        </template>
      </LayoutColumns>
    </div>

    <template v-if="true">
      <AdsLivewrappedAd
        class="no-print hidden lg:block"
        id="lb2"
        :full-width="true"
      />
      <AdsLivewrappedAd
        class="no-print lg:hidden order-15"
        id="mob4"
        :full-width="true"
      />
    </template>
    <template v-if="true">
      <CommonSectionLabel
        class="no-print hidden lg:block"
        title="Birthdays"
        link="Routes.Birthdays"
        link-label="ShowAll"
        :link-to-paper="true"
      />
      <ListBirthdays
        class="no-print hidden lg:block"
        :count="15"
        type="slider"
        placement="full"
      />
    </template>
    <AdsLivewrappedAd
      v-if="false"
      class="no-print hidden lg:block"
      id="lb2"
      :full-width="true"
    />
    <div class="container no-print">
      <CommonSectionLabel
        class="no-print order-12 lg:order-none"
        title="Debate"
      />
      <LayoutColumns>
        <template #left>
          <AdsLivewrappedAd
            v-if="true"
            class="hidden lg:block"
            id="rec3"
          />

          <ListColumnists class="order-14 lg:order-none" />
          <template v-if="false">
            <AdsLivewrappedAd class="hidden lg:block" id="rec4" />
          </template>
        </template>
        <template #main>
          <ListArticles
            class="order-13 lg:order-none"
            :count="6"
            :offset="0"
            type="column"
            :show-more="true"
            placement="main"
          />
          <template v-if="true">
            <CommonSectionLabel
              class="order-24 lg:order-none"
              title="Krönikor"
            />
            <ListArticles
              class="order-25 lg:order-none"
              :count="2"
              :offset="0"
              :article-type="90"
              type="articletype"
              :show-more="true"
              placement="main"
            />
          </template>
          <template v-else-if="false">
            <AdsLivewrappedAd class="lg:hidden" id="mob5" />
            <CommonSectionLabel
              class="order-24 lg:order-none"
              title="Spaltister"
            />
            <ListArticles
              class="order-25 lg:order-none"
              :count="2"
              :offset="0"
              :article-type="72"
              type="articletype"
              :show-more="true"
              placement="main"
            />
          </template>
          <AdsLivewrappedAd
            v-if="true"
            class="lg:hidden order-26"
            id="mob5"
          />
        </template>
        <template #right>
          <ListCalendar
            class="order-17 lg:order-none"
            :count="5"
            placement="side"
          />
          <ListDecisionchains
            v-if="true"
            class="hidden lg:block"
            label="DecisionChainShort"
            :count="5"
            placement="side"
          />
          <AdsLivewrappedAd
            v-if="false"
            class="hidden lg:block"
            id="rec5"
          />
        </template>
      </LayoutColumns>
    </div>
    <CommonSectionLabel
      class="no-print hidden lg:block"
      title="Papers"
      link="Routes.Papers"
      link-label="ShowAllPapers"
    />
    <ListPapers class="no-print hidden lg:block" />
    <AdsLivewrappedAd
      class="no-print hidden lg:block"
      id="lb3"
      :full-width="true"
    />
    <div v-if="true" class="container no-print">
      <CommonSectionLabel class="no-print hidden lg:block" title="NamesNews" />
      <LayoutColumns>
        <template #left>
          <ListBirthdays class="order-16 lg:order-none" placement="side" />
        </template>
        <template #main>
          <ListNames
            class="hidden lg:block"
            :show-more="true"
            placement="main"
          />
        </template>
        <template #right>
          <AdsLivewrappedAd class="hidden lg:block" id="rec4" />
        </template>
      </LayoutColumns>
    </div>
    <template v-if="true">
      <AdsLivewrappedAd
        class="no-print hidden lg:block"
        id="lb4"
        :full-width="true"
      />
      <CommonSectionLabel
        class="no-print order-17 lg:order-none"
        title="DecisionChain"
        link="decisionchain/"
      />
      <ListDecisionchains
        class="no-print order-18 lg:order-none"
        :count="15"
        type="slider"
        placement="full"
      />
    </template>
    <div class="container no-print">
      <LayoutColumns>
        <template #left>
          <AdsLivewrappedAd class="hidden lg:block" id="rec5" />
          <CommonPromo
            v-if="siteConfig.allowAts"
            class="order-22 lg:order-none"
            icon-type="conversation"
            header1="14DaysTrial"
            header2="Ourmedia"
            cta-label="ReadMoreAndSignup"
            :cta="{ name: 'subscription' }"
            disclaimer="AllreadySubscribed"
            hide-if-logged-in
            placement="side"
          />
        </template>
        <template #main>
          <ListArticles
            class="order-30 lg:order-none"
            :count="8"
            :article-type="1"
            type="articletype"
            :show-more="true"
            placement="main"
          />
        </template>
        <template #right>
          <AdsLivewrappedAd class="hidden lg:block" id="rec6" />
          <ListJobs class="hidden lg:block" :count="5" :random="true" />
        </template>
      </LayoutColumns>
    </div>
  </div>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()
const siteConfig = useSiteConfig()
const requestUrl = useRequestURL()
const route = useRoute()

const sagaPaperStore = useSagaPaperStore()
const userStore = useUserStore()

useHead(() => {
  // Have to use config.public.site here, due to composables not working in useHead
  const siteConfig = config.public.site

  if (!route.name || typeof route.name !== 'string') {
    // FALLBACK
    return createBasicHeadMetaData(siteConfig, requestUrl)
  }

  return createDefaultHeadMetaData(
    siteConfig,
    requestUrl,
    route.name,
    sagaPaperStore.activePaper.name
  )
})
</script>
